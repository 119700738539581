.App {
  background-color: #000000;
  text-align: center;
  font-size: calc(10px + 2vmin);
  overflow:hidden;
  position:absolute;
  height:100%;
  width:100%;
}

.three {
  position:absolute;
  display: flex;
  min-height:60vh;
}

@keyframes glowwhite {
  from {
    color: rgba(255, 255, 255, 1);
  }
  to {
    color: rgba(38, 62, 71, 0.11);
  }
}

@keyframes glowblue {
  from {
    color: rgba(31, 60, 85, 1);
  }
  to {
    color: rgba(31, 60, 85, 0.11);
  }
}


.title {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  position:absolute;
  top:40%;
  left:0%;
  width:100%;
  font-size:2.2em;
}

.fiber, .optic {
  animation-duration: 4s;
  animation-name: glowwhite;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function:cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.stake {
  animation-duration: 4s;
  animation-name: glowblue;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function:cubic-bezier(0.6, 0.04, 0.98, 0.335);
}


.App-header {
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
